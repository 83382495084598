import { UpgradeSubscriptionItems } from '../../components/upgrade-subscription-items';
import { subscriptionsSelector } from '../../redux/subscriptions.selector';
import { subscriptionsSlice } from '../../redux/subscriptions.slice';
import { TYPE_OF_UPDATE_OPTIONS } from '@root/modules/dashboard/types';
import { ICanBeSwitchedToItem, IPurchasedProduct } from '@root/modules/products/types/purchased-product';
import { Button, ModalComponent, ButtonContainer } from '@root/shared/ui';
import { ArrowLeftWithoutColorIcon as ArrowLeftIcon } from '@root/shared/ui/icons/arrow-left';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

interface IProps {
  isOpen: boolean;
  close: () => void;
  confirm: () => void;
  typeOfOption?: TYPE_OF_UPDATE_OPTIONS;
  items: ICanBeSwitchedToItem[] | IPurchasedProduct;
}

export const UpgradeSubscriptionModal: FC<IProps> = ({ isOpen, close, confirm, items, typeOfOption }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('subscriptions', { keyPrefix: typeOfOption === TYPE_OF_UPDATE_OPTIONS.RENEW ? 'switch_renewal_modal' : 'upgrade_subscription_modal' });

  const { activeUpgrade } = useSelector(subscriptionsSelector);

  const handleCloseModal = useCallback(() => {
    dispatch(subscriptionsSlice.actions.resetActiveUpgrade());
    close();
  }, [dispatch, close]);

  return (
    <ModalComponent
      className='md:max-w-lg'
      modalIsOpen={isOpen}
      closeModal={handleCloseModal}
      headerTitle={
        <div className='flex flex-col items-start px-4 lg:px-6'>
          <span className='mb-2'>{t('title.header')}</span>
          <span className='!font-normal text-[14px] text-left'>{t('title.text')}</span>
        </div>
      }
    >
      <div>
        {Array.isArray(items) && <UpgradeSubscriptionItems items={items} />}

        <ButtonContainer withPadding className='flex justify-center !px-4 lg:px-10'>
          <Button className='w-full lg:w-auto lg:self-center lg:py-3 lg:px-10' onClick={confirm} disabled={!activeUpgrade}>
            {t('confirm-button')}
            <span className='hidden lg:block w-5 ml-3 -mr-2 text-gray-100 rotate-180'>
              <ArrowLeftIcon />
            </span>
          </Button>
        </ButtonContainer>
      </div>
    </ModalComponent>
  );
};
